<template>
    <div>
      <transition name="vload">
        <div v-show="isLoading" class="Loader">
          <div class="sk-cube-grid">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
          </div>
          <!--<i class="fa fa-spinner fa-spin fa-5x fa-fw" style="color:green"></i>-->
        </div>
      </transition>
        <div id="main-wrapper">
            <Header></Header>
          <section class="fixed mypage-contents-fixed">
            <div class="container">
              <div class="row">
                <MyPageContents class="col-lg-3 col-sm-12" v-on:logout="logout" v-on:delete-account="deleteAccount" :user-data="userData" :validation-errors="deleteAccountValidationErrors"></MyPageContents>
                <div class="col-lg-9 col-sm-12 mx-auto mypage-right-frame">
                  <JobInfoForm v-on:on-click-send-button="registerJobInfo" :is-edit="false" :job-info-data="{}" :validation-errors="validationErrors"></JobInfoForm>
                </div>
              </div>
            </div>
          </section>
            <Footer></Footer>
        </div>
    </div>
</template>
<script>
    import Header from "@/views/components/Header";
    import Footer from "@/views/components/Footer";
    import JobInfoForm from "@/views/jobinfo/component/JobInfoForm";
    import MixIn from "@/common/mixin";
    import ApiMixIn from "@/common/api_mixin";
    import Vue from "vue";
    import MyPageContents from "@/views/components/MyPageContents.vue";
    import MyPageMixIn from "@/common/mypage_mixin";

    export default {
        name: "JobInfoList",
        components: {MyPageContents, Footer, Header, JobInfoForm},
        mixins: [MyPageMixIn,MixIn, ApiMixIn],
        methods: {
            async registerJobInfo(body) {
                const response = await this.postApi("jobInfo/register", body, true);
                if (response) {
                    this.userData = response.data.user;
                    Vue.prototype.$user = response.data.user;
                    this.pushResult("登録完了", "登録しました");
                }
            }
        }
    }
</script>

<style scoped>

</style>